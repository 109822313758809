const routeLinks = {
  home: "/",
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password/:token",
  selectMerchant: "/select-merchant",
  welcome: "/welcome/:token",
  generateQR: "/generate-qr-code",
  changePassword: "/account/change-password",
  setting: "/setting",
  transactionHistory: "/transaction-history",
  listAccount: "/account",
  createAccount: "/account/create",
  editAccount: "/account/:id",
  transactionStatistic: "/transaction-statistic",
  new_withdrawal_orders: "/withdrawal/orders/new",
  withdrawal_orders: "/withdrawal/orders",
  withdrawal_orders_details: "/withdrawal/orders-details/:id",
  customers: "/customers",
  createCustomer: "/customers/create",
  editCustomer: "/customers/edit/:id",
  withdrawal_products: "/withdrawal/products",
  withdrawal_products_details: "/withdrawal/products-details/:id",
  createVoucher: "/voucher/create",
  voucherDetails: "/voucher/details/:id",
  voucher: "/voucher",
};

export default routeLinks;
