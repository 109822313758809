import { parseStoreData } from "./helpers";

export const actionType = {
  INIT_STORE: "STORE.INIT",
  UPDATE_STORE: "STORE.UPDATE",
  CLEAR_STORE: "STORE.CLEAR",
};

const actions = {
  initStore: (store) => {
    return {
      type: actionType.INIT_STORE,
      data: store,
    };
  },
  updateStore: (newSetting) => (dispatch) => {
    dispatch({
      type: actionType.UPDATE_STORE,
      data: parseStoreData(newSetting),
    });
  },
  clearStore: () => {
    return {
      type: actionType.CLEAR_STORE,
    };
  },
};

export default actions;
