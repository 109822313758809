import { formatCurrency } from "utils";

const my = {
  routeName: {
    home: "Halaman utama",
    stores: "Lokasi",
    QR: "Jana kod QR",
    transactionHistory: "Sejarah transaksi",
    setting: "Tetapan",
    adminManage: "Pengurusan pentadbiran",
    logs: "Rekod tukaran syiling",
    products: "Produk",
    account: "Akaun",
    customers: "Customers",
    new_order: "New Order",
    new_voucher: "Add a voucher",
    voucher: "Voucher",
  },
  logOut: {
    label: "Log keluar",
    confirmTitle: "Sahkan log keluar",
    confirmMessage: "Adakah anda pasti mahu log keluar?",
  },
  openStore: {
    confirmTitle: "Sedang mengemaskini status buka",
    confirmMessage:
      "Membuka lokasi bermaksud pelanggan boleh datang untuk tukaran syiling. Sila sahkan anda mahu membuka lokasi",
  },
  closeStore: {
    confirmTitle: "Sedang mengemaskini status tutup",
    confirmMessage:
      "Menutup lokasi bermaksud pelanggan tidak boleh datang untuk tukaran syiling. Sila sahkan anda mahu menutup lokasi",
  },
  confirmActiveStore: {
    title: "Aktifkan lokasi",
    message:
      "Lokasi aktif dapat log masuk ke tapak web penjual dan menerima tukaran syiling. Sila sahkan untuk aktifkan",
  },
  confirmInactiveStore: {
    title: "Nyahaktifkan lokasi",
    message:
      "Lokasi yang tidak aktif tidak dapat log masuk ke tapak web penjual dan tidak dapat menerima tukaran syiling. Sila sahkan untuk nyahaktifkan",
  },
  confirmTransferred: {
    title: "Sahkan syiling diterima",
    message: "Pengesahan akan mengemas kini status transaksi menjadi SELESAI. Adakah anda mahu teruskan?",
  },
  statisticTb: {
    totalTransaction: "Jumlah transaksi",
    totalRM: "Jumlah kutipan RM",
    totalCompleted: "Jumlah transaksi selesai",
    RMCompleted: "Jumlah RM selesai",
    toBank: "Jumlah transaksi ke bank",
    RMToBank: "Jumlah RM ke bank",
    toCharity: "Jumlah transaksi derma",
    RMToCharity: "Jumlah RM derma",
    RMFee: "Jumlah yuran transaksi",
  },
  confirmInactiveAccount: {
    title: "Nyahaktifkan akaun peniaga",
    message: "Akaun ini akan dilog keluar daripada semua peranti yang tidak dapat log masuk semula. Teruskan?",
  },
  confirmActiveAccount: {
    title: "Aktifkan akaun peniaga",
    message: "Akaun ini boleh log masuk ke portal ini. Teruskan?",
  },

  address: "Alamat",
  closed: "Tutup",
  code: "Kod",
  digit: "Digit",
  email: "Emel",
  endTime: "Waktu Tutup",
  goBack: "Kembali",
  language: "Bahasa",
  open: "Buka",
  password: "Kata laluan",
  startTime: "Waktu buka",
  storeID: "ID lokasi",
  storeEmail: "Emel lokasi",
  storeName: "Nama lokasi",
  storePhone: "Nombor telefon lokasi",
  storeLogo: "Logo lokasi",
  selectFile: "Pilih fail",
  storeAddress: "Alamat lokasi",
  submit: "Hantar",
  save: "Simpan",

  monday: "Isnin",
  tuesday: "Selasa",
  wednesday: "Rabu",
  thursday: "Khamis",
  friday: "Jumaat",
  saturday: "Sabtu",
  sunday: "Ahad",

  coinDeposit: "TUKARAN SYILING",
  status: "STATUS",
  success: "BERJAYA",
  transactionId: "ID TRANSAKSI",
  clientFullName: "NAMA PENUH PELANGGAN",
  clientPhoneNumber: "NOMBOR TELEFON BIMBIT PELANGGAN",
  timeCreated: "MASA DICIPTA",
  transactionFee: "TCAJ TRANSAKSI",
  back: "KEMBALI",
  depositAmount: "Jumlah syiling diterima (RM)",
  amount: "JUMLAH (RM)",
  depositFee: "Jumlah Caj (RM)",
  transactionDetails: "Transaction Details",
  // TDOO: make "Generate QR code" new line
  confirmGenerateQrCode: (amount) => (
    <div>
      Jumlah terakhir yang akan dipindahkan: <span style={{ fontWeight: "700" }}>RM{amount}</span>. Teruskan dengan kod
      QR?
    </div>
  ),
  pleaseInputAmountRM: "Sla masukkan jumlah (RM)",
  generateQrCode: "Jana kod QR",
  expiresIn: "Luput dalam masa",
  qrCodeExpired: "KOD QR LUPUT",
  regenerateQrCode: "Jana kod QR yang baru",
  changeAmount: "Tukar jumlah",
  createTransactionSuccess: "Transaksi dimulakan",
  depositTimestamp: "Masa tukaran",

  id: "ID",
  phoneNumber: "Nombor telefon bimbit",
  customerName: "Nama pelanggan",
  help: "Bantuan",
  requestHelp: "Mohon bantuan",
  timeAt: "Masa",
  dateRange: "Tempoh",
  updateSettingsSuccess: "Tetapan telah dikemaskini",
  resendEmail: "Hantar semula emel",
  emailVerifySent: "Kod 6-digit telah dihantar kepada anda melalui emel dan SMS.",
  latitude: "Latitud",
  longitude: "Longitud",
  miniumAmountRequired: "Jumlah minimum yang diperlukan",
  locationSetting: "Tetapan lokasi",
  needHelp: "Perlu bantuan?",
  tooltipFindLatitude: "Cari atau masukkan latitud dan longitud",
  createStore: "Cipta lokasi",
  editStore: "Ubah suai lokasi",
  storeNotFound: "Lokasi tidak dijumpai",
  backToListStore: "Kembali ke senarai lokasi",
  action: "Tindakan",
  transferred: "Syiling diterima",
  export: "Eksport",
  transactionDetail: "Butiran transaksi",
  sendTo: "Hantar kepada",
  charity: "Amal jariah",
  bank: "Bank",
  bankAccountName: "Nama pemegang akaun bank",
  bankAccountNumber: "Nombor akaun bank",
  transactionStatus: "Status transaksi",
  bankName: "Nama bank",
  search: "Cari",
  resetFilter: "Set semula tapisan",
  login: "Log masuk",
  verifyEmail: "Sahkan alamat emel",
  changeOnlineHours: "Kemaskini waktu operasi",
  clickToAddTimeSlot: "Klik sini untuk tambah slot waktu yang baru bagi hari",
  clickHereToOnline: "Klik di sini untuk membuka lokasi",
  offline: "Tutup",
  online: "Buka",
  active: "Aktif",

  forgotPassword: "Lupa kata laluan?",
  account: "Akaun",
  changePassword: "Ubah kata laluan",
  currentPassword: "Kata laluan kini",
  newPassword: "Kata laluan baharu",
  confirmNewPassword: "Sahkan kata laluan baharu",
  passwordNotMatch: "Kata laluan baharu tidak sepadan",
  changePasswordSuccess: "Kata laluan berjaya diubah",
  backToLogin: "Kembali ke log masuk",
  forgotPass: {
    emailSent: "Semak emel anda",
    message1: "Emel telah dikirim kepada",
    message2: "Ikuti arahan dalam emel untuk set semula kata laluan anda",
    messageEnterMail: "Masukkan alamat emel anda. Kami akan mengirim pautan untuk set semula kata laluan anda",
  },
  resetPassword: "Set semula kata laluan",
  continue: "Teruskan",

  createAdmin: "Cipta admin",
  fullName: "Nama penuh",
  role: "Peranan",
  admin: "Admin",
  superAdmin: "Super admin",
  userActions: {
    genQrCode: "Jana Kod QR",
    viewTransactions: "Lihat sejarah transaksi",
    editSettings: "Kemaskini tetapan",
    manageAccount: "Urus Akaun",
  },
  createSuccess: "Berjaya dicipta",
  sendInvitationEmail: "Hantar emel undangan",
  objSendInvitation: {
    title: "Hantar emel undangan kepada lokasi",
    content: "Anda akan menghantar emel undangan. Teruskan?",
    success: "Emel undangan berjaya dihantar",
    error1: "Tidak dapat menghantar emel kepada ",
    btnReSend: "Hantar semula emel",
    sendingEmail: "Emel sedang dihantar",
  },
  objExportError: {
    title: "Ralat data",
    isDone: "Transaksi SELESAI",
    isMissingData: "Data tak lengkap dalam transaksi",
    isConfirmed: "Transaksi DISAHKAN",
  },
  wantToContinue: "Teruskan?",
  exportBulkPayment: "Eksport untuk Bayaran Pukal",
  requestBody: "Badan Permintaan",
  responseMessage: "Mesej respon",
  statusCode: "Kod Status",
  api: "API",
  createdAt: "Dicipta pada",
  adminNotFound: "Admin tidak dijumpai",
  backToAdminManage: "Kembali kepada pengurusan admin",
  confirmInactiveAdmin: {
    title: "Nyahaktifkan akaun admin",
    message: "Akaun ini akan dilog keluar daripada semua peranti dan tidak dapat log masuk semula. Teruskan?",
  },
  confirmActiveAmin: {
    title: "Aktifkan akaun pengurus",
    message: "Akaun ini boleh log masuk ke halaman ini. Teruskan?",
  },
  editAdmin: "Ubah suai admin",
  updateSuccess: "Kemaskini berjaya",
  waitingTransfer: "Menunggu pemindahan",
  coinReceived: "Wang Syiling diterima",
  paid: "Bayar",
  requesthelpTitle: "MOHON BANTUAN UNTUK TRANSAKSI INI",
  purchaseRequired: "Pembelian disyaratkan",
  purchaseRequiredToolTip:
    "Aktifkan pilihan ini jika pembelian di lokasi ini disyaratkan sebelum tukaran syiling dibenarkan",
  alertPurchaseRequire: (amount) =>
    amount ? `Pembelian minimum RM${formatCurrency(amount)} disyaratkan` : "Pembelian disyaratkan",
  exportingFile: "Mengeksport fail",
  statisticBy: "Statistik mengikut",
  date: "Tarikh",
  week: "Minggu",
  day: "Hari",
  month: "Bulan",
  createAccount: "Cipta akaun",
  editAccount: "Ubah suai akaun",
  accountNotFound: "Akaun tidak dijumpai.",
  backToAccountManage: "Kembali ke Pengurusan Akaun",
  finance: "Pegawai Kewangan",
  operator: "Pegawai Pengendali",
  createdBy: "Dikendalikan Oleh",
  receiptEmailSuccess: "Resit emel telah berjaya dihantar",
  sendReceiptViaEmail: {
    label: "Hantar resit",
    confirmTitle: "Hantar resit pengesahan",
    confirmMessage: (email, customerName) => (
      <div>
        Adakah anda pasti ingin menghantar resit ini kepada <b>{customerName}</b> di alamat emel <b>{email}</b>?
      </div>
    ),
  },
  coinAmount: "Jumlah wang syiling",
  coinAmountRM: "Jumlah wang syiling (RM)",
  originalCoinAmount: "Jumlah asal wang syiling",
  serviceFee: "Caj perkhidmatan",
  totalKOCEKAmount: "Jumlah KOCEK",
  totalReceived: "Jumlah yang akan dipindahkan",
  payoutAmount: "Jumlah dibayar",
  serviceFeeInformation: "Informasi Caj perkhidmatan",
  usingGlobalFee: "Kategori: Caj global",
  usingCustomizedFee: "Kategori: Caj ubah suai",
  feeName: "Nama caj",
  resetAmount: "Set semula jumlah",
  change: "Tukar",
  changeServiceFee: "Tukar caj perkhidmatan",
  changeServiceFeeContent:
    "Anda akan menukar yuran perkhidmatan untuk transaksi ini. Adakah anda pasti mahu meneruskan?",
  proceed: "Teruskan",
  cancel: "Kembali",
  selectServiceFee: "Sila pilih caj perkhidmatan di bawah",
  minimumRequiredToConvert: "Syiling minimum yang diperlukan untuk ditukar",
  andAbove: "dan ke atas",
  confirm: "Teruskan",
  confirmation: "Pengesahan",
  generate: "Jana kod QR",
  payoutMethod: "Kaedah pembayaran",
  totalConversion: "Jumlah penukaran amaun",
  voucherIncentive: "Jumlah insentif baucar",
  totalVoucher: "Jumlah baucar",
  voucherAmount: "Jumlah baucar untuk diedarkan",
  voucherImportanceMessage: "Important:",
  voucherMessage:
    "Please note that the entire amount must be converted into vouchers and cannot be split between a bank transfer and vouchers.",
  confirmGenerateQrCodeVoucher: (amount) => (
    <div>
      The voucher amount to be distributed: <span style={{ fontWeight: "700" }}>RM{amount}</span>. Generate QR code?
    </div>
  ),
  updateVoucherStockSuccess: (stock, voucher_name) => `${stock} voucher for ${voucher_name} successfully added`,
  conversionSummaryTitle: "Ringkasan penukaran",
  scanQrcode: "Imbas kod QR",
  scanQrcodeText: "Tunjukkan kod QR ini kepada pelanggan, untuk melihat ringkasan penukaran",
  borderText: "masukkan kod secara manual",
  KOCEKInAmount: "KOCEK In amount",
};

export default my;
