import React, { lazy } from "react";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";

import {
  HomeOutlined,
  UnorderedListOutlined,
  ShopOutlined,
  QrcodeOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { isLoggedInSelector, isMerchantSelector, userRoleSelector } from "reduxStore/Auth/selectors";
import routeLinks from "./routeLinks";
import { merchantRole } from "variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faUsers, faBox, faTicket } from "@fortawesome/free-solid-svg-icons";

const routesByRole = {
  admin: [
    // remove because it is moved to admin portal
    // {
    //   path: routeLinks.listAccount,
    //   component: lazy(() => import('pages/Account')),
    //   name: 'routeName.account',
    //   icon: <UserOutlined />,
    //   exact: true,
    //   category: "settings"
    // },
    {
      path: routeLinks.createAccount,
      component: lazy(() => import("pages/AccountDetail")),
      exact: true,
      showOnSideBar: false,
    },
    {
      path: routeLinks.editAccount,
      component: lazy(() => import("pages/AccountDetail/Edit")),
      showOnSideBar: false,
    },
  ],
  finance: [
    {
      path: routeLinks.transactionHistory,
      component: lazy(() => import("pages/TransactionsHistory")),
      name: "routeName.transactionHistory",
      icon: <UnorderedListOutlined />,
      category: "kocek_in",
    },
    {
      path: routeLinks.setting,
      component: lazy(() => import("pages/Setting")),
      name: "routeName.setting",
      icon: <ShopOutlined />,
      category: "settings",
    },
  ],
  selectMerchant: [
    {
      path: routeLinks.selectMerchant,
      component: lazy(() => import("components/ModalSelectMerchant")),
      showOnSideBar: false,
    },
  ],
  authorize: [
    {
      path: routeLinks.home,
      component: lazy(() => import("pages/Home")),
      name: "routeName.home",
      icon: <HomeOutlined />,
      exact: true,
    },
    {
      path: routeLinks.generateQR,
      component: lazy(() => import("pages/GenerateQRCode")),
      name: "routeName.QR",
      icon: <QrcodeOutlined />,
      category: "kocek_in",
    },
    {
      path: routeLinks.transactionStatistic,
      component: lazy(() => import("pages/TransactionsStatistic")),
      name: "routeName.statistic",
      icon: <LineChartOutlined />,
      category: "kocek_in",
    },
    {
      path: routeLinks.createVoucher,
      component: lazy(() => import("src/pages/MydinVoucher/NewVoucher")),
      backIcon: true,
      showOnSideBar: false,
      name: "routeName.new_voucher",
      category: "kocek_in",
    },{
      path: routeLinks.voucherDetails,
      component: lazy(() => import("src/pages/MydinVoucher/VoucherDetails")),
      backIcon: true,
      showOnSideBar: false,
      name: "routeName.voucher_details",
      category: "kocek_in",
    },
    {
      path: routeLinks.voucher,
      component: lazy(() => import("src/pages/MydinVoucher/VoucherListing")),
      backIcon: true,
      showOnSideBar: true,
      name: "routeName.voucher",
      icon: <FontAwesomeIcon icon={faTicket} style={{ marginRight: 10, fontSize: 14, marginLeft: 2 }} />,
      category: "kocek_in",
    },
    {
      path: routeLinks.withdrawal_orders,
      component: lazy(() => import("src/pages/CoinWithdrawal/OrderListing")),
      name: "routeName.orders",
      icon: <FontAwesomeIcon icon={faClipboard} style={{ marginRight: 10, fontSize: 14, marginLeft: 2 }} />,
      exact: true,
      category: "kocek_out",
    },
    {
      path: routeLinks.new_withdrawal_orders,
      component: lazy(() => import("src/pages/CoinWithdrawal/OrderListing/NewOrder")),
      name: "routeName.new_order",
      backIcon: true,
      showOnSideBar: false,
      category: "kocek_out",
    },
    {
      path: routeLinks.withdrawal_orders_details,
      component: lazy(() => import("src/pages/CoinWithdrawal/OrderListing/OrderDetails")),
      name: "order_details",
      backIcon: true,
      showOnSideBar: false,
      category: "kocek_out",
    },
    {
      path: routeLinks.customers,
      component: lazy(() => import("src/pages/Customers")),
      name: "routeName.customers",
      showOnSideBar: true,
      category: "general",
      exact: true,
      icon: <FontAwesomeIcon icon={faUsers} style={{ marginRight: 10, fontSize: 14, marginLeft: 2 }} />,
    },
    {
      path: routeLinks.createCustomer,
      component: lazy(() => import("src/pages/Customers/CustomerDetails")),
      name: "routeName.new_customers",
      showOnSideBar: false,
      backIcon: true,
      category: "general",
    },
    {
      path: routeLinks.editCustomer,
      component: lazy(() => import("src/pages/Customers/CustomerDetails/edit")),
      name: "edit_customers",
      showOnSideBar: false,
      backIcon: true,
      category: "general",
    },
    {
      path: routeLinks.withdrawal_products,
      component: lazy(() => import("src/pages/CoinWithdrawal/ProductListing")),
      name: "routeName.products",
      icon: <FontAwesomeIcon icon={faBox} style={{ marginRight: 10, fontSize: 14, marginLeft: 2 }} />,
      exact: true,
      category: "kocek_out",
    },
    {
      path: routeLinks.withdrawal_products_details,
      component: lazy(() => import("src/pages/CoinWithdrawal/ProductListing/ProductDetails")),
      name: "product_details",
      backIcon: true,
      showOnSideBar: false,
      category: "kocek_out",
    },
    {
      path: routeLinks.changePassword,
      component: lazy(() => import("pages/ChangePassword")),
      exact: true,
      showOnSideBar: false,
    },
  ],
  unAuthorize: [
    {
      path: routeLinks.login,
      component: lazy(() => import("pages/Login")),
      showOnSideBar: false,
    },

    {
      path: routeLinks.forgotPassword,
      component: lazy(() => import("pages/ForgotPassword")),
      showOnSideBar: false,
    },
    {
      path: routeLinks.resetPassword,
      component: lazy(() => import("pages/ResetPassword")),
      showOnSideBar: false,
    },
    {
      path: routeLinks.welcome,
      component: lazy(() => import("pages/Welcome")),
      showOnSideBar: false,
    },
  ],
};

const useRouteByRole = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const isMerchantSelected = useSelector(isMerchantSelector);
  const userRole = useSelector(userRoleSelector);

  if (!isLoggedIn) return routesByRole.unAuthorize;
  if (!isMerchantSelected) return routesByRole.selectMerchant;

  //get route
  switch (userRole) {
    case merchantRole.admin:
      return [routesByRole.authorize, routesByRole.finance, routesByRole.admin].flat();
    case merchantRole.super_admin:
      return [routesByRole.authorize, routesByRole.finance, routesByRole.admin].flat();
    case merchantRole.finance:
      return [routesByRole.authorize, routesByRole.finance].flat();
    case merchantRole.retail_staff:
      return [routesByRole.authorize, routesByRole.finance].flat();
    case merchantRole.operator:
    default:
      return routesByRole.authorize;
  }
};

export default useRouteByRole;
