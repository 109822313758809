import get from "lodash/get";
import { createSelector } from "@reduxjs/toolkit";

import { formatStoreData } from "./helpers";

const _storeSettingSelector = (state) => get(state, "store.store");

export const storeSelector = createSelector(_storeSettingSelector, formatStoreData);

export const storeNameSelector = (state) => get(storeSelector(state), "store_name");

export const storeMinimumRequireSelector = (state) => get(storeSelector(state), "min_amount");

export const storeRequirePurchaseSelector = (state) => get(storeSelector(state), "purchase_required");
