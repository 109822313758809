import { formatCurrency } from "utils";

const en = {
  routeName: {
    home: "Home",
    stores: "Locations",
    QR: "Generate QR code",
    transactionHistory: "Transactions History",
    setting: "Merchant",
    adminManage: "Admin management",
    logs: "Coin conversion logs",
    statistic: "Statistic",
    account: "Accounts",
    orders: "Orders",
    products: "Products",
    new_order: "New Order",
    customers: "Customers",
    new_customers: "Add New Customer",
    new_voucher: "Add a voucher",
    voucher: "Voucher",
  },
  logOut: {
    label: "Log out",
    confirmTitle: "Log out confirmation",
    confirmMessage: "Are you sure you want to logout?",
  },
  openStore: {
    confirmTitle: "Online",
    confirmMessage:
      "Customers are able to visit registered stores for coins conversion based on chosen operation hours.",
  },
  closeStore: {
    confirmTitle: "Going offline",
    confirmMessage:
      "Going offline means customer can't come to you for coin conversion. Please confirm that you want to go offline",
  },
  confirmActiveStore: {
    title: "Activate a location",
    message:
      "An active location can login to the merchant website and accept coin conversion. Please confirm to activate",
  },
  confirmInactiveStore: {
    title: "Deactivate a location",
    message:
      "A deactivated location cannot login to the merchant website and cannot accept coin conversion. Please confirm to deactivate",
  },
  confirmTransferred: {
    title: "Confirm coins received",
    message: "This will update the transaction status to DONE. Do you want to continue?",
  },
  statisticTb: {
    totalTransaction: "Total transaction",
    totalRM: "Total collection RM",
    totalCompleted: "Total transaction completed",
    RMCompleted: "Total RM completed",
    toBank: "Total transaction to bank",
    RMToBank: "Total RM to bank",
    toCharity: "Total transaction to charity",
    RMToCharity: "Total RM to charity",
    average: "Average RM per transaction",
    RMFee: "Total transaction fee",
  },
  confirmInactiveAccount: {
    title: "Deactivate a merchant account",
    message: "This account will be logged out from every device which will no longer be able to login again. Continue?",
  },
  confirmActiveAccount: {
    title: "Activate a merchant account",
    message: "This account can login to this portal. Continue?",
  },

  address: "Address",
  closed: "Closed",
  code: "Code",
  digit: "Digit",
  email: "Email",
  endTime: "End time",
  goBack: "Go back",
  language: "Language",
  open: "Open",
  password: "Password",
  startTime: "Start time",
  storeID: "Location ID",
  storeEmail: "Location email",
  storeName: "Location Name",
  storePhone: "Location telephone number",
  storeLogo: "Location logo",
  selectFile: "Select file",
  storeAddress: "Location address",
  submit: "Submit",
  save: "Save",

  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",

  coinDeposit: "COIN CONVERSION",
  status: "STATUS",
  success: "SUCCESS",
  transactionId: "TRANSACTION ID",
  clientFullName: "CUSTOMER FULL NAME",
  clientPhoneNumber: "CUSTOMER MOBILE PHONE NUMBER",
  timeCreated: "TIME CREATED",
  transactionFee: "Service Fee",
  back: "BACK",
  depositAmount: "Final coins amount (RM)",
  voucherIncentive: "Voucher Incentive (RM)",
  amount: "Amount(RM)",
  depositFee: "Fees amount (RM)",
  transactionDetails: "Transaction Details",
  // TDOO: make "Generate QR code" new line
  confirmGenerateQrCode: (amount) => (
    <div>
      Final amount to be transferred is: <span style={{ fontWeight: "700" }}>RM{amount}</span>. Generate QR code?
    </div>
  ),
  pleaseInputAmountRM: "Enter the value of coins the customer wants to deposit. Eg: RM10.20",
  generateQrCode: "Generate QR code",
  expiresIn: "Expires in",
  qrCodeExpired: "QR code expired",
  regenerateQrCode: "Regenerate QR code",
  changeAmount: "Change amount",
  createTransactionSuccess: "Transaction started",
  depositTimestamp: "Conversion timestamp",

  id: "ID",
  phoneNumber: "Mobile phone number",
  customerName: "Customer name",
  help: "Help",
  requestHelp: "Request help",
  timeAt: "Time at",
  dateRange: "Date range",
  updateSettingsSuccess: "Settings updated",
  resendEmail: "Resend email",
  emailVerifySent: "A 6-digit code has been sent to you via email. Enter it below",
  latitude: "Latitude",
  longitude: "Longitude",
  miniumAmountRequired: "Minimum amount required",
  locationSetting: "Location setting",
  needHelp: "Need help?",
  tooltipFindLatitude: "Find or enter latitude and longitude",
  createStore: "Create location",
  editStore: "Edit location",
  storeNotFound: "Store not found",
  backToListStore: "Back to location list",
  action: "Action",
  transferred: "Transfer completed",
  export: "Export",
  transactionDetail: "Transaction detail",
  sendTo: "Payout Method",
  charity: "Charity",
  bank: "Bank Transfer",
  bankAccountName: "Bank account holder",
  bankAccountNumber: "Bank account number",
  transactionStatus: "Transaction status",
  bankName: "Bank name",
  search: "Search",
  resetFilter: "Reset filter",
  login: "Login",
  verifyEmail: "Verify email address",
  changeOnlineHours: "Edit operating hours",
  clickToAddTimeSlot: "Click here to add another time slot for",
  clickHereToOnline: "Click here to go online",
  offline: "Offline",
  online: "Online",
  active: "Active",

  account: "Account",
  changePassword: "Change password",
  currentPassword: "Current password",
  newPassword: "New password",
  confirmNewPassword: "Confirm new password",
  passwordNotMatch: "New passwords do not match!",
  changePasswordSuccess: "Password changed successfully",
  forgotPassword: "Forgot password?",
  backToLogin: "Back to log in",
  forgotPass: {
    emailSent: "Check your email",
    message1: "An email has been sent to",
    message2: "Follow the directions in the email to reset your password",
    messageEnterMail: "Enter your email address. We'll send you a link to reset your password",
  },
  resetPassword: "Reset password",
  continue: "Continue",

  welcome: "Welcome",
  messCreateYourPassword: "Create your password so we can get it to work",
  createPasswordSuccess: "Password created successfully",
  loginToStart: "Let's login to start your work",
  createPassword: "Create password",
  createAdmin: "Create admin",
  fullName: "Full name",
  role: "Role",
  admin: "Admin",
  superAdmin: "Super admin",
  userActions: {
    genQrCode: "Generate QR code",
    viewTransactions: "View transactions history",
    editSettings: "Edit settings",
    manageAccount: "Manage Accounts",
  },
  createSuccess: "Create successfully",
  sendInvitationEmail: "Send invitation email",
  objSendInvitation: {
    title: "Send invitation email to location",
    content: "You will send an invitation email. Do you want to continue?",
    success: "Email sent successfully",
    error1: "Unable to send email to",
    btnReSend: "Resend email",
    sendingEmail: "Sending invitation email",
  },
  objExportError: {
    title: "Data error",
    isDone: "Transaction DONE",
    isMissingData: "Missing data transaction",
    isConfirmed: "Transaction CONFIRMED",
  },
  wantToContinue: "Continue?",
  exportBulkPayment: "Export for Bulk Payment",
  requestBody: "Request Body",
  responseMessage: "Response message",
  statusCode: "Status code",
  api: "API",
  createdAt: "Created at",
  adminNotFound: "Admin not found.",
  backToAdminManage: "Back to Admin management",
  confirmInactiveAdmin: {
    title: "Deactivate an admin account",
    message: "This account will be logged out from every device and will no longer be able to login again. Continue?",
  },
  confirmActiveAmin: {
    title: "Activate an admin account",
    message: "This account can login to this page. Continue?",
  },
  editAdmin: "Edit admin",
  updateSuccess: "Update successfully",
  waitingTransfer: "Waiting for transfer",
  coinReceived: "Coin received",
  paid: "Paid",
  requestHelpTitle: "REQUEST HELP FOR THIS TRANSACTION",
  locationOperationHours: "MyPay Coin Conversion Operating Hours",
  purchaseRequired: "Purchase required",
  purchaseRequiredToolTip:
    "Activate this option if a purchase at this location is required before coin conversion is allowed",
  alertPurchaseRequire: (amount) =>
    amount ? `Minimum RM${formatCurrency(amount)} purchase required` : "Purchase required",
  exportingFile: "Exporting file",
  statisticBy: "Statistic by",
  date: "Date",
  week: "Week",
  day: "Day",
  month: "Month",
  createAccount: "Create account",
  editAccount: "Edit account",
  accountNotFound: "Account not found.",
  backToAccountManage: "Back to Account Management",
  finance: "Finance",
  operator: "Operator",
  createdBy: "Created By",
  receiptEmailSuccess: "Receipt Email sent successfully",
  sendReceiptViaEmail: {
    label: "Send Receipt",
    confirmTitle: "Send Receipt Confirmation",
    confirmMessage: (email, customerName) => (
      <div>
        Are you sure you want to send this receipt to <b>{customerName}</b> at <b>{email}</b>?
      </div>
    ),
  },
  coinAmount: "Coin amount",
  coinAmountRM: "Coin Amount (RM)",
  originalCoinAmount: "Original coin amount",
  serviceFee: "Service fee",
  totalKOCEKAmount: "Total KOCEK amount",
  totalReceived: "Amount to be transferred",
  payoutAmount: "Payout amount",
  serviceFeeInformation: "Service Fee Information",
  usingGlobalFee: "Category: Global fee",
  usingCustomizedFee: "Category: Customized fee",
  updateStockSuccess: (stock, product_name) =>
    `${Math.abs(stock)} item for ${product_name} successfully ${stock > 0 ? "added" : "removed"}`,
  feeName: "Fee name",
  resetAmount: "Reset Amount",
  change: "Change",
  changeServiceFee: "Change service fee",
  changeServiceFeeContent:
    "You are about to change the service fee for this transaction. Are you sure you want to proceed?",
  proceed: "Proceed",
  cancel: "Cancel",
  selectServiceFee: "Please select service fee below",
  minimumRequiredToConvert: "Minimum required coins to be converted",
  andAbove: "and above",
  confirm: "Confirm",
  confirmation: "Confirmation",
  generate: "Generate",
  payoutMethod: "Payout method",
  totalConversion: "Total conversion amount",
  // voucherIncentive: "Voucher incentive amount",
  totalVoucher: "Total voucher amount",
  voucherAmount: "Voucher amount to be distributed",
  voucherImportanceMessage: "Important:",
  voucherMessage:
    "Please note that the entire amount must be converted into vouchers and cannot be split between a bank transfer and vouchers.",
  confirmGenerateQrCodeVoucher: (amount) => (
    <div>
      The voucher amount to be distributed: <span style={{ fontWeight: "700" }}>RM{amount}</span>. Generate QR code?
    </div>
  ),
  updateVoucherStockSuccess: (stock, voucher_name) => `${stock} voucher for ${voucher_name} successfully added`,
  conversionSummaryTitle: "Conversion Summary",
  scanQrcode: "Scan QR Code",
  scanQrcodeText: "Show this QR code to customer, to view the conversion summary",
  borderText: "or enter code manually",
  KOCEKInAmount: "KOCEK In amount",
};

export default en;
