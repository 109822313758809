export const HOST = process.env.REACT_APP_API_URL;
export const KOCEK_HOST = process.env.REACT_APP_KOCEK_API_URL;
export const API_ID = process.env.REACT_APP_API_ID;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const PRTNRID = process.env.REACT_APP_PRTNRID;
const URI = "coin-conversion/merchant";
const URIAdmin = "coin-conversion/admin";

export const LOGIN = `${HOST}/${URI}/login`;
export const SELECT_MERCHANT = `${HOST}/${URI}/select-merchant`;
export const VERIFY_EMAIL_CODE = `${HOST}/${URI}/verify-email-code-v2`;
export const LOGIN_BY_TOKEN = `${HOST}/${URI}/login-by-token-v2`;
export const RESET_PASSWORD = `${HOST}/${URI}/reset-password`;
export const CHANGE_PASSWORD = `${HOST}/${URI}/password`;
export const LOGOUT = `${HOST}/${URI}/logout`;
export const UPDATE_SETTINGS = `${HOST}/${URI}/update-settings`;
export const CREATE_TEMP_TRANSACTION = `${HOST}/${URI}/create-temp-transaction`;
export const GET_TRANSACTION = `${HOST}/${URI}/coin-deposit-transaction`;
export const CREATE_REQUEST_HELP = `${HOST}/${URI}/request-help`;
export const MERCHANT_ACCOUNT = `${HOST}/${URI}/merchant-account`;
export const SEND_RECEIPT_VIA_EMAIL = `${HOST}/${URI}/send-receipt`;
export const GET_ACTIVE_FEE = `${HOST}/${URI}/active-fee`;
export const CALCULATE_FEE = `${HOST}/${URI}/calc-fee`;

export const TRANSACTION_STATISTIC = `${HOST}/${URI}/statistic-transaction`;
export const SEND_EMAIL_INVITATION = `${HOST}/${URIAdmin}/send-invitation`;
export const GET_WITHDRAWAL_PRODUCT_LISTING = `${KOCEK_HOST}/product/get-product-list`;
export const GENERATE_WITHDRAWAL_TOKEN = `${KOCEK_HOST}/auth/generate-auth-token`;
export const GET_ORDER_LISTING = `${KOCEK_HOST}/order/get-order-listing`;

export const GET_GLOBAL_FEE = `${HOST}/${URIAdmin}/global-fee`;
export const GET_CUSTOMER_LISTING = `${KOCEK_HOST}/client/get-client`;
export const UPDATE_ORDER_STATUS = `${KOCEK_HOST}/order/update-status`;
export const ADD_CUSTOMER = `${KOCEK_HOST}/client/add-client`;
export const EDIT_CUSTOMER = `${KOCEK_HOST}/client/clients/`;
export const GET_INDUSTRY_LIST = `${KOCEK_HOST}/business-sector/get-business-sector-list`;
export const ADD_PAYMENT_DETAILS = `${KOCEK_HOST}/payment/add-payment-details`;
export const ADD_PAYMENT_DETAILS_IMAGE = `${KOCEK_HOST}/payment/add-payment-details-image`;
export const GET_CLIENT_TOKEN = `${KOCEK_HOST}/client/get-client`;
export const UPDATE_PRODUCT_STOCK = `${KOCEK_HOST}/product/update-stock`;
export const GET_PRODUCT_STOCK_MOVEMENT = `${KOCEK_HOST}/product/get-stock-movement`;
export const GET_CUSTOMER_ACTIVITY_LOG = `${KOCEK_HOST}/client/get-client-activity-log`;
export const GET_ORDER_LOG = `${KOCEK_HOST}/order/get-order-log`;
export const RESEND_ORDER_EMAIL = `${KOCEK_HOST}/order/resend-order-email`;

export const CREATE_VOUCHER = `${HOST}/${URI}/create-voucher`;
export const GET_VOUCHER_LIST = `${HOST}/${URI}/get-voucher-list`;
export const UPDATE_VOUCHER_STOCK = `${HOST}/${URI}/update-voucher-stock`;
export const GET_VOUCHER_STOCK_MOVEMENT = `${HOST}/${URI}/get-voucher-movement`;
export const GET_VOUCHER_STOCK = `${HOST}/${URI}/get-voucher-stock`;
