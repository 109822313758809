import { actionType } from "./actions";

let initialState = {
  store: null,
};

function store(state = initialState, action) {
  switch (action.type) {
    case actionType.INIT_STORE:
      return { ...state, store: action.data };
    case actionType.UPDATE_STORE:
      return { ...state, store: { ...state.store, ...action.data } };
    case actionType.CLEAR_STORE:
      return { ...state, store: null };
    default:
      return state;
  }
}

export default store;
